import { Client } from "./account-api-client";
import { EweComponent } from "./p-ewe-component";

function displayConsents(apiClient, consents) {
  for (const consent of consents) {
    const component = document.querySelector(
      `.p-ewe-component[data-consent-type="${consent.consentTypeId}"]`,
    ) as EweComponent;
    component.apiClient = apiClient;
    component.consent = consent;
  }
}

document.addEventListener("authentication-success", async () => {
  const apiClient = new Client();
  const consents = await apiClient.fetchConsents();
  displayConsents(apiClient, consents);
});
